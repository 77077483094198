var App = window['App'] = window['App'] || {};
App.changeLanguage = () => {

    if (document.querySelectorAll('[data-activelang]').length) {

        $('[data-activelang]').click(function(e) {
            e.preventDefault();
            $('.language-submenu').toggleClass('active');
        })

    }

}

App.closeOpenLanguageMenu = () => {
    
    if ($('.language-submenu').hasClass('active')) {
        $('.language-submenu').removeClass('active');
    }

}