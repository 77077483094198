import { createSlideRange } from "./_functions";

var App = window['App'] = window['App'] || {};
// ukryj gdy ktos kliknie poza filter container
App.hideFilterSelectOnClickOutside = () => {

    $('html').click(function(e) {

        if ( $('.options-content.active').length ) {

            var closestFilterSelect = $(e.target).closest('.options-content.active');
            var closestFilterInput = $(e.target).closest('.filter-options .filter-input');

            if ( closestFilterSelect.length === 0 && closestFilterInput.length === 0  ) {

                $('.options-content.active').removeClass('active');
                $('.filter-options .filter-input.active').removeClass('active');
                $('.filter-options .options-content.active').removeClass('active');

            }

        }

    });

}

App.showFilteredRanges = () => {

    //klikniecie w rate lub cene

    if ($('#priceRanges').length) {

        createSlideRange("rangeCena", 0, 0, 15000, "priceMin", "priceMax", true);
        createSlideRange("rangeRok", 0, 0, 1, "yearbookMin", "yearbookMax", false);

    }


}

App.filteringModuleTabs = () => {

    if ( $('.filtering-module').length ) {

    App.hideFilterSelectOnClickOutside();
    App.showFilteredRanges();

    // klikniecie w tab i wyswietlenie kontenera z opcjami do wyboru
    $('[data-tabfor]').on('click', function(e) {
        var $this = $(this);
        var allTabs = $('.filter-options .filter-input');
        var allOptionsContent = $('.filter-options .options-content');

        var closest = e.target.closest('.filter-input');
        var smallerWindow = null;

        var closestIndx = $(closest).index();

        // zmienne dla buttona do wyswietlenia odpowiedniego contentu z buttonami
        var tabName = closest.dataset.tabfor;

        // usun wszystkie aktywne klasy dla buttonow Tabow
        for(var i = 0; i<allTabs.length; i++) {

            var actualTab = allTabs[i];
            var actualContent = allOptionsContent[i];

            if (closest == actualTab) {
                $(this).toggleClass('active');
                $('.options-content[data-taboption='+ closest.dataset.tabfor +']').insertAfter($(this));
                $('.options-content[data-taboption='+ closest.dataset.tabfor +']').addClass('active');

                $('.options-content[data-taboption='+ closest.dataset.tabfor +']').toggleClass('active');

            } else {
                $(actualTab).removeClass('active');
                $(actualContent).removeClass('active');
            }
        }

        if (closest.classList.contains('active')) {
            $('.options-content[data-taboption='+ closest.dataset.tabfor +']').addClass('active');
        } else {
            $('.options-content').removeClass('active');
        }

    });
    }
}