var App = window['App'] = window['App'] || {};
// FORMULARZ NIEUŻYWANY NA STRONIE
// BRAK PODPIĘCIA Z API
// BARTEK 5.11.2020
App.otherCarForm = () => {
    // formularz "Nie znalazłeś nic dla siebie?"
    if ( $('#offerForm').length ) {

        var $form = $('#offerForm');

        $form.validate({
            rules: {
                client_email: {
                    required: true,
                    emailCheck: true
                },
                client_mobile: {
                    required: true,
                    phonePL: true
                },
                client_name: {
                    required: true,
                    lettersonly: true
                },
                agree1: "required",
                car_model: {
                    required: true
                },
                car_year: {
                    required: true,
                    digitsonly: true,
                    minlength: 4,
                    min: 1890,
                    max: new Date().getFullYear()
                }
            },
            messages: {
                client_email: {
                    required: window.language.requiredEmail
                },
                client_mobile: {
                    required: window.language.requiredPhone,
                    phonePL: window.language.correctPhone
                },
                client_name: {
                    required: window.language.requiredNameSurname,
                },
                agree1: window.language.requiredField,
                car_model: {
                    required: window.language.requiredField
                },
                car_year: {
                    required: window.language.requiredCarYear,
                    minlength: window.language.minLenghtField,
                    min: window.language.minValue1890Field,
                    max: window.language.maxValueIsField + new Date().getFullYear()
                }
            },
            errorPlacement: function(error, element) {
                if (element.attr("id") == "agree1") {
                    error.appendTo("#offerForm .agreement1");
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {

                console.warn('Wysłano formularz');
            },
            invalidHandler: function(form) {

                console.error('Wystapil blad przy walidacji');
            }
        }).settings.ignore = [];

    }
}