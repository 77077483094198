var App = window['App'] = window['App'] || {};
App.typoCarsShowList = () => {

    if ( $('.typocars').length ) {

        $('.single-car.unavailable a').on('click', function(e) {
            e.preventDefault();
        })

        $('a[data-show]').on('click', function(e) {
            e.preventDefault();

            if (this.dataset.show == "true") {
                // zwin liste poniewaz jest TRUE => ROZWINIETA
                this.innerText = window.language.showList;
                this.dataset.show = "false";
                $('.typocars .car-listing').slideUp();
            } else {
                // rozwin liste poniewaz jest FALSE => ZAMKNIETA
                this.innerText = window.language.hideList;
                this.dataset.show = "true";
                $('.typocars .car-listing').slideDown();

            }


        })
    }
}