var App = window['App'] = window['App'] || {};
App.controlHeight = () => {

    if ( $('.homepage-slider').length ) {
        $('.slider-desc .small-section-title').matchHeight();
    }

    if ( $('.articles-list').length ) {
        $('.article-block .art-desc').matchHeight();
        $('.article-block .art-title').matchHeight();
    }

    if ( $('.more-arts-news').length ) {
        $('.article-block .block-for-text .art-title').matchHeight();
        $('.article-block .block-for-text .art-desc').matchHeight();
    }
    if ( $('.gallery-desc').length ) {
        $('.gallery-desc').matchHeight();
    }

    if ( $('.car-blocks-container').length ) {
        // $('.car-blocks-container .car-blocks .car-block').matchHeight();
        $('.car-blocks-container .car-blocks .carinformation-block').matchHeight();

        // $(window).resize(function() {
        //     $.fn.matchHeight._update();
        // })

    }

}