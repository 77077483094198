var App = window['App'] = window['App'] || {};
App.filterNews = () => {

    if ( $('#searchNews').length ) {

        $('.btn-search-blog').click(function(e) {
            e.preventDefault();
            $('#searchNews').submit();
        })

        $('#searchNews').on('submit', function(e) {
            e.preventDefault();

            var selectedData = $(this).serialize();

            var searchedData = `?type=news&${selectedData}`;

            // console.warn('URUCHOM LOADER');
            $('.loading').show();

            $.get(`${window.rootHomepage}api/${searchedData}`, function(data, status) {

                App.displayFilteredNews(data);

            });




        });

    }
}

App.displayFilteredNews = data => {

    $('#searchResults').html('');
    var startHtml = '';
    var resultHtml = '';
    var endHtml = '';

    if (data.length) {



        startHtml = `<div class="article-blocks">`;

        for(var i=0; i<data.length; i++) {

            resultHtml +=   `<div class="article-block">
                                <a href="${data[i].httpUrl}" class="img-block-image article-block-img" style="background-image: url('${data[i].image[0].url}')">
                                    <div class="link-layer">
                                        <span class="info-link">${window.language.readMore}</span>
                                    </div>
                                </a>
                                <div class="block-for-text">
                                    <a href="${data[i].httpUrl}" class="small-section-title art-title">${data[i].title[0]}</a>
                                    <div class="art-desc description">${data[i].teaser}</div>
                                    <a href="${data[i].httpUrl}" class="btn btn--darkblue btn--ico">${window.language.readMore}</a>
                                </div>
                            </div>`;

        }

        endHtml = `</div>`;

    } else {

        resultHtml = `<div class="row medium-row columns" style="margin: 30px 0"><div class="row columns"><div class="blog-header"><p class="section-title">${window.language.nothingFound}</p></div></div></div>`;


        // console.log('brak artykułów');
    }

    $('#searchResults').html(startHtml+resultHtml+endHtml);





    $('.loading').hide();

    $('.article-block .art-desc').matchHeight();
    $('.article-block .art-title').matchHeight();
    if ( $('.more-arts-news').length ) {
        $('.article-block .block-for-text .art-title').matchHeight();
        $('.article-block .block-for-text .art-desc').matchHeight();
    }
    $.fn.matchHeight._update();

}