var App = window['App'] = window['App'] || {};
App.askAboutCar = () => {

    if ($('[data-askAbout]').length) {

        $('[data-askAbout]').click(function(e) {
            e.preventDefault();

            $('.ask-popup').addClass('active');
            $('.popup').addClass('active');
            // Dodaj do inputów info o samochodzie
            $('#carPopupPrice').val( $(this).attr('data-askprice') );
            $('#carPopupName').val( $(this).attr('data-askname') );
            $('#carPopupPath').val( $(this).attr('data-askpath') );
            $('#carPopupClientName').val( $(this).attr('') );
            $('#carPopupClientMail').val( $(this).attr('') );
            $('#carPopupClientPhone').val( $(this).attr('') );

        });

        $('.popup .close-popup').click(function(e) {
            $('.popup.active').removeClass('success');
            $('.ask-popup').removeClass('active');
            $('.popup').removeClass('active');
            // Wyczyść inputy i ukryj sukces

            $('[data-popupForm]').show();
            $('[data-popupFormSuccess]').hide();
            $('#carPopupPrice').val('');
            $('#carPopupName').val('');
            $('#carPopupPath').val('');
        })

    }


}

App.checkSimilar = () => {

    if( $('[data-checkSimilar]').length ) {

        $('[data-checkSimilar]').click(function(e) {
            e.preventDefault();

            var positionSimilar = $('.similar-car');
            if(positionSimilar.length) {
                var positionOffset = $('.similar-car').offset().top;
                $("html, body").animate({ scrollTop: positionOffset - 30 }, 500);
            }

        })


    }

}

App.hidePopupClickOutside = () => {



    $('html').click(function(e) {

        $('.ask-popup.active').click(function() {
            $('.popup.active').removeClass('success');
            $('.ask-popup').removeClass('active');
            $('.popup').removeClass('active');

            $('[data-popupForm]').show();
            $('[data-popupFormSuccess]').hide();
            $('#carPopupPrice').val('');
            $('#carPopupName').val('');
            $('#carPopupPath').val('');

        })

    });



}