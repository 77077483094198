var App = window['App'] = window['App'] || {};
// NEWSLETTER NIEUŻYWANY NA STRONIE
// BRAK PODPIĘCIA Z API
// BARTEK 5.11.2020
App.newsletterForm = () => {
    if ( $('#newsletterForm').length ) {

        var $form = $('#newsletterForm');

        $form.validate({
            rules: {
                client_email: {
                    required: true,
                    emailCheck: true
                },
                newsletter_agree1: "required"
            },
            messages: {
                client_email: {
                    required: window.language.requiredEmail
                },
                newsletter_agree1: window.language.requiredField
            },
            errorPlacement: function(error, element) {
                if (element.attr("id") == "newsletter_agree1") {
                    error.appendTo($("#newsletterForm .agreement1"));
                } else if (element.attr("id") == "client_email") {
                    error.insertAfter($('#newsletterForm .newsletter-inputs'))
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {

                $('[data-newsletter-success]').show();
                $('#newsletterForm').hide();

                // console.warn('Wysłano formularz');
            },
            invalidHandler: function(form) {

                // console.error('Wystapil blad przy walidacji');
            }
        }).settings.ignore = [];

    }
}