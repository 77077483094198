var App = window['App'] = window['App'] || {};
App.initSliderHeader = () => {
    // Tworzy Swiper gdy obrazów jest więcej niż 1
    const slides = document.querySelector('.slider-header');
    if(slides){
        const images = slides.querySelectorAll('header');
        if(images.length>1){
            createSwiperSlider();
        }
    }
}

const createSwiperSlider = () => {

    const headerSlider = new Swiper('.slider-header', {
        spaceBetween: 0,
        loop: true,
        slidesPerView: 1,
        pagination: {
            clickable: true,
            el: '.swiper-pagination'
        }
    });

}