var App = window['App'] = window['App'] || {};
App.showCookie = () => {
    window.addEventListener("load", function(){
        window.cookieconsent.initialise({
            "cookie.expiryDays": 7,
            "palette": {
            "popup": {
                "background": "#000"
            },
            "button": {
                "background": "transparent",
                "text": "#fff",
                "border": "#fff"
            }
            },
            "content": {
            "message": window.language.cookiesMessage,
            "dismiss": window.language.cookiesAgree,
            "link": window.language.cookiesMoreInfo,
            "href": window.rootHomepage + '"' + window.language.cookiesURL + '"'
            }
        })});
}