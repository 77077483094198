var App = window['App'] = window['App'] || {};
App.makeSliderTilesActive = () => {

    var windowWidth = window.innerWidth;

    if (windowWidth > 1365) {
        // wyswietlanie czterech białych slide'ow
        $('.swiper-wrapper .swiper-slide').css('opacity', '0.2');
        $('.swiper-slide.swiper-slide-active').css('opacity', '1');
        $('.swiper-slide.swiper-slide-next').css('opacity', '1');
        $('.swiper-slide.swiper-slide-next').next().css('opacity', '1');
        $('.swiper-slide.swiper-slide-prev').css('opacity', '1');
        $('.swiper-slide.swiper-slide-prev').prev().css('opacity', '1');

    } else if (windowWidth < 1366 && windowWidth > 990) {
        // wyswietlanie trzech białych slide'ow
        $('.swiper-wrapper .swiper-slide').css('opacity', '0.2');
        $('.swiper-slide.swiper-slide-active').css('opacity', '1');
        $('.swiper-slide.swiper-slide-prev').css('opacity', '1');
        $('.swiper-slide.swiper-slide-next').css('opacity', '1');
    } else if (windowWidth < 991) {
        // wyswietlanie tylko jednego białego slide'u
        $('.swiper-wrapper .swiper-slide').css('opacity', '0.2');
        $('.swiper-slide.swiper-slide-active').css('opacity', '1');
    }

}

App.homeSlider = () => {

    if ( document.querySelectorAll('.homepage-slider').length ) {

        App.preventSliderHashLinks();

        var slidesQty = document.querySelectorAll('.swiper-slide').length;

        var homeSlider = new Swiper('.homepage-slider', {
            slidesPerView: 6,
            loopAdditionalSlides: 100,
            loopedSlides: slidesQty+27,
            spacesBetween: 0,
            initialSlide: parseInt(slidesQty/2),
            loop: true,
            centeredSlides: true,
            slidesPerGroup: 1,
            keyboard: {
                enabled: true,
                onlyInViewport: true
            },
            breakpoints: {
                1366: {
                    slidesPerView: 4,
                },
                991: {
                    slidesPerView: 3
                },
                480: {
                    slidesPerView: 2
                }
            }

        });

        App.makeSliderTilesActive()

        homeSlider.on('transitionEnd', function() {
            App.makeSliderTilesActive();
            homeSlider.update();
        })

        $(window).resize(function() {
            homeSlider.update();
        })

    }
}

App.preventSliderHashLinks = () => {

    $('.slider-img').on('click', function(e) {

        if ( $(this).attr('href') == "#" ) {
            e.preventDefault();
        }

    })

    $('.swiper-slide .slider-desc .small-section-title').on('click', function(e) {

        if ( $(this).attr('href') == "#" ) {
            e.preventDefault();
        }

    })

}