var App = window['App'] = window['App'] || {};

App.filterForm = () => {

    if ($('#filterForm').length) {

        $('#filterForm').on('submit', function(e) {
            e.preventDefault();

            $('.filter-options .filter-input.active').removeClass('active');
            $('.filter-options .options-content.active').removeClass('active');

            var selectedData = $(this).serialize();
            var selectedDataArray = $(this).serializeArray();

            const categoryChanges = selectedDataArray.find( el => el.name === 'category' );
            if(categoryChanges && categoryChanges.value){
              switch(categoryChanges.value){
                case 'modern':
                  $('#catTitle').html('Modern Classic');
                  break;
                  case 'young':
                    $('#catTitle').html('Youngtimer');
                  break;
                  case 'old':
                    $('#catTitle').html('Oldtimer');
                  break;
                  case 'classic':
                    $('#catTitle').html('Classic AMG');
                  break;
              }
              $('#catDesc').html(window.language.category[categoryChanges.value]);
              $('#catInfo').show();
            } else {
              $('#catInfo').hide();
            }

            createButtons(selectedDataArray);

            var searchedData = `?type=car&${selectedData}`;

            // console.warn('URUCHOM LOADER');
            $('.loading').show();

            $.get(`${window.rootHomepage}api/${searchedData}`, function(data, status) {

                App.displayFilteredData(data);
            })

            function createButtons(data) {


                $('.filter-footer .selected-options').html('');

                var arrayOfButtons = [];

                for (var i=0; i<data.length; i++) {

                    if (data[i].name == "yearbookMin") {
                        var yearbookMinValue = data[i].value;
                    }
                    else if (data[i].name == "yearbookMax") {
                        var yearbookMaxValue = data[i].value;
                    }
                    else if (data[i].name == "priceMin") {
                        var priceMin = data[i].value;
                    }
                    else if (data[i].name == "priceMax") {
                        var priceMax = data[i].value;
                    }
                    else if (data[i].name == "soon") {
                        var isSoonOffer = data[i].value;
                    }
                    else if (data[i].name == 'category'){
                        var category = data[i].value;
                    }
                    else {
                        arrayOfButtons.push(data[i]);
                    }
                }
                switch(category){
                  case 'modern':
                    createFilterBtn('Modern Classic', 'category', 'modern', 'category')
                    break;
                    case 'young':
                      createFilterBtn('Youngtimer', 'category', 'young', 'category')
                    break;
                    case 'old':
                      createFilterBtn('Oldtimer', 'category', 'old', 'category')
                    break;
                    case 'classic':
                      createFilterBtn('Classic AMG', 'category', 'classic', 'category')
                    break;
                }

                if (isSoonOffer == 1) {
                    createFilterBtn(window.language.availableSoonModels, "soon", "1", "soon");
                }

                if ( !( yearbookMinValue == $('#rangeRok').data('minyear') && yearbookMaxValue == $('#rangeRok').data('maxyear') ) ) {
                    createFilterBtn(`${window.language.yearbook} ${yearbookMinValue}-${yearbookMaxValue}`, ["yearbookMin", "yearbookMax"], [yearbookMinValue, yearbookMaxValue], "rok");
                    // ZROB BUTTON ROK PRODUKCJI
                }

                if ( !( priceMin == $('#rangeCena').data('minyear') && priceMax == $('#rangeCena').data('maxyear') ) ) {
                    createFilterBtn(`${window.language.price} ${priceMin}zł-${priceMax}zł`, ["priceMin", "priceMax"], [priceMin, priceMax], "cena");
                    // ZROB BUTTON CENY JESLI WARTOSC JETS INNA NIZ DOMYSLNA
                }

                // zrób buttony dla reszty wyszukiwania

                for (var j=0; j<arrayOfButtons.length; j++) {

                    createFilterBtn(arrayOfButtons[j].value, arrayOfButtons[j].name, arrayOfButtons[j].value, arrayOfButtons[j].value);

                }

                App.filterSearchBtns();

            }

        });

        function createFilterBtn(text, dataSearchName, dataSearchValue, typeOfSearch) {

            if (!$('.filter-footer .selected-options span[data-typeOfSearch="'+typeOfSearch+'"]').length) {

                $('.filter-footer .selected-options').append(`<span class="btn btn--darkblue btn--closeico btn--closeico" data-searchValue="${dataSearchValue}" data-searchName="${dataSearchName}" data-typeOfSearch="${typeOfSearch}">${text}</span>`);

            }

        }

        $('.filterJS').on('click', function(e) {
            e.preventDefault();
            $('#filterForm').submit();
        });

    }
}

App.filterSearchBtns = () => {

    $('.filter-footer .selected-options .btn').on('click', function(e) {
        // funkcja klikniecia na button wyszukiwania i usuwania go z parametrow szukania
        e.preventDefault();

        var thisTypeOfSearch = this.dataset.typeofsearch;
        var thisName = this.dataset.searchname;
        var thisValue = this.dataset.searchvalue;

        console.log(thisTypeOfSearch);

        if (thisTypeOfSearch != "rok" && thisTypeOfSearch != "cena") {
            $(`[name="${thisName}"][value="${thisValue}"]`).prop('checked', false);

            $('#filterForm').submit();

        } else {
            var firstValue = thisName.split(',')[0];
            var secondValue = thisName.split(',')[1];

            if (thisTypeOfSearch == "rok") {
                var rangeElem = "#rangeRok";
                var step = 1;
            } else if (thisTypeOfSearch == "cena") {
                var rangeElem = "#rangeCena";
                var step = 1000;
            }

            $(`[name="${firstValue}"]`).val( $(rangeElem).data('minyear') );
            $(`[name="${secondValue}"]`).val( $(rangeElem).data('maxyear') );
            $(rangeElem + ' .noUi-base .noUi-origin:eq(0)').css('transform', 'translate(-100%, 0px)');
            $(rangeElem + ' .noUi-base .noUi-origin:eq(0) .noUi-handle').attr('aria-valuenow',0.0);
            $(rangeElem + ' .noUi-base .noUi-origin:eq(0) .noUi-handle').attr('aria-valuetext', $(rangeElem).data('minyear'));
            $(rangeElem + ' .noUi-base .noUi-connect').css('transform', 'translate(0%, 0px) scale(1,1)');
            $(rangeElem + ' .noUi-base .noUi-origin:eq(1)').css('transform', 'translate(0%, 0px)');
            $(rangeElem + ' .noUi-base .noUi-origin:eq(1) .noUi-handle').attr('aria-valuenow',100.0);
            $(rangeElem + ' .noUi-base .noUi-origin:eq(1) .noUi-handle').attr('aria-valuetext', $(rangeElem).data('maxyear'));
            $(rangeElem).prev('.min-range').html( $(rangeElem).data('minyear') );
            $(rangeElem).next('.max-range').html( $(rangeElem).data('maxyear') );

            $('#filterForm').submit();

        }


    })

}

App.displayFilteredData = data => {



    var countOfFounded = data.length;
    $('#founded').html(countOfFounded);
    $('#wordcount').html(window.language.wordCount);

    $('#searchResults').html('');

    var htmlStart = '';
    var resultHtml = '';
    var htmlEnd = '';

    if (countOfFounded > 0) {

        $('#founded').css('color', 'white');
        $('#wordcount').css('color', 'white');

        htmlStart = `<div class="row medium-row car-blocks-container"><div class="car-blocks">`;
        htmlEnd = `</div></div>`;

        for(var i=0; i<countOfFounded; i++) {

            // wyswietl HTML dla samochodu
            var carLink = data[i].httpUrl;
            var carImg = (data[i] && data[i].image.length)? data[i].image[0].url : null;
            var carIsSoon = data[i].soon_on_offer;
            var carPrice = parseInt(data[i].price_gross).toFixed(0);
            var carName = carIsSoon ? `<p class="car-name">${data[i].title[0]}</p>` : `<a href="${carLink}" class="car-name">${data[i].title[0]}</a>`;

            var carButtons = carIsSoon ? `<div class="btn-group"><a href="#" class="btn btn--darkblue btn--ico" data-askabout>${window.language.askAbout}</a></div>` : `<div class="btn-group"><a href="#" class="btn btn--darkblue btn--ico" data-askabout>${window.language.askAbout}</a><a data-gtm-button-details href="${carLink}" class="btn btn--outline_onlight btn--ico">${window.language.details}</a></div>`;

            var carHeaderImg = carIsSoon ? `<div class="img-block-image car-block-img coming-soon-car" style="background-image: url('${carImg}')"><div class="link-layer"><p class="info-link">${window.language.carSoonAvailable}</p></div></div>` : `<a href="${carLink}" class="img-block-image car-block-img" style="background-image: url('${carImg}')"><div class="link-layer"><span class="info-link">${window.language.watchMore}</span></div></a>`;

            var rocznik = data[i].yearbook ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_calendar.svg" alt="" class="car-icon"><p class="description">${window.language.yearbook} ${data[i].yearbook}</p></div>` : '';

            var przebieg = data[i].mileage ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_arrow_przebieg.svg" alt="" class="car-icon"><p class="description">${window.language.mileage} ${data[i].mileage} km</p></div>` : '';

            var silnik = data[i].engine ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_engine.svg" alt="" class="car-icon"><p class="description">${window.language.engine} ${data[i].engine}cm<sup>3</sup></p></div>` : '';

            var paliwo = data[i].fuel_type ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_fuel.svg" alt="" class="car-icon"><p class="description">${window.language.fuel} ${data[i].fuel_type}</p></div>` : '';

            var moc = data[i].power ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_wheel.svg" alt="" class="car-icon"><p class="description">${window.language.power} ${data[i].power[0]}</p></div>` : '';

            var skrzynia = data[i].yearbook ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_gears.svg" alt="" class="car-icon"><p class="description">${data[i].transmission}</p></div>` : '';

            resultHtml +=   `
                <div class="car-block">
                    ${carHeaderImg}
                    <div class="carinformation-block">
                        <div class="car-desc">
                            <p class="description">${window.language.priceGross} ${carPrice} PLN</p>
                            ${carName}
                        </div>
                        <div class="car-icons clearfix large-up-2 zero-collapse">
                            ${rocznik}
                            ${przebieg}
                            ${silnik}
                            ${paliwo}
                            ${moc}
                            ${skrzynia}
                        </div>
                        ${carButtons}
                    </div>
                </div>`;

        }



    } else {

        $('#founded').css('color', 'red');
        $('#wordcount').css('color', 'red');
        $('#wordcount').html(window.language.noResultsFound);

        // alert('brak wynikow');
        // resultHtml = `<div class="row columns"><p class="smaller-section-title">Brak wyników o podanych kryteriach</p></div>`;
    }

    $('#searchResults').html(htmlStart+resultHtml+htmlEnd);

    App.askAboutCar();

    if ( $('.car-blocks-container').length ) {
        $('.car-blocks-container .car-blocks .carinformation-block').matchHeight();


    }

    // $('#resultsForSearch').html(resultHtml);

    $('.loading').hide();


}