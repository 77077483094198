var App = window['App'] = window['App'] || {};
var youtubeVideos = [];
var instagramPosts = [];
var twitterPosts = [];
var blogPosts = [];
var facebookPosts = [];

var newData = {
    youtube: [],
    instagram: [],
    twitter: [],
    blog: [],
    facebookPosts: []
}

App.loadSocialMediaSection = () => {

    if ( $('.social-media-section').length ) {

        $('.loading-socialblock').show();

        $.get(`${window.rootHomepage}api/?type=socialmedia`, function(data, status) {


            //załaduj wszystkie POSTY i zapisz ODEBRANE dane do zmiennych globalnych
            try{
                var newData = JSON.parse(data);
                youtubeVideos = newData.youtube;
                instagramPosts = newData.instagram;
                twitterPosts = newData.twitter;
                blogPosts = newData.blog;
                facebookPosts = newData['facebook'] || [];
            } catch(e){
                $('#socialblockResult').remove();
                $('.loading-socialblock').hide();
                // console.error('Błąd podczas parsowania social media - JSON');
            }

         }).done(function() {

            App.displaySocialPosts();
            // console.log('Ładowanie social zakonczone');
            $('.loading-socialblock').hide();
         })

    }
}

App.displaySocialPosts = () => {

    var resultHtml = '';

    function showTwitter() {
        if (twitterPosts.length) {

            resultHtml += `<div class="social-preview-box twitter">`;
    
            for(var i=0; i<twitterPosts.length; i++) {
    
                if (i==3) break;
    
                resultHtml +=   `<div class="social-info">
                                    <div class="social-header clearfix">
                                        <p class="description float-left"><a href="https://twitter.com/${ twitterPosts[0].name.screen_name }">@${ twitterPosts[0].name.screen_name }</a></p>
                                        <div class="social-time float-right">
                                            <img src="${templateHttp}dest/img/clock_gray.svg" alt="" class="clock">
                                            <p class="description">${twitterPosts[0].date}</p>
                                        </div>
                                    </div>
                                    <div class="description-xl author">${twitterPosts[0].name.name}</div>
                                    <div class="content">
                                        <p class="description">${twitterPosts[0].text}</p>
                                    </div>
                                </div>`;
    
    
                twitterPosts.splice(0, 1);
    
    
            }
    
    
            resultHtml += `<div class="social-info link-to-twitter">
                                <a href="https://twitter.com/dudacarspoznan" target="_blank" class="check_out_in_social">
                                    <img src="${templateHttp}dest/img/twitter_in_social.svg" alt="" class="icon_checkout">
                                    <p class="description-xl">${window.language.moreOnTwitter}</p>
                                </a>
                            </div>
                        </div>`;
    
        }
    }

    function showBlog() {
        if (blogPosts.length) {

            resultHtml += `<div class="social-preview-box blog">`;
    
            for(var i=0; i<blogPosts.length; i++) {
    
                var thisBlogPost = JSON.parse(blogPosts[0]);


                if (i==1) break;
    
                resultHtml +=   `<div class="social-info">
                                    <div class="social-header" style="background-image: url('${thisBlogPost.image[0].url}')">
                                        <div class="social-time float-right">
                                            <img src="${templateHttp}dest/img/clock_white.svg" alt="" class="clock">
                                            <p class="description">${ displayDate(new Date(1000* thisBlogPost.created)) }</p>
                                        </div>
                                    </div>
                                    <div class="social-content">
                                        <a href="${thisBlogPost.httpUrl}" class="description-xl blog-title">${thisBlogPost.title[0]}</a>
                                        <p class="description blog-content">${thisBlogPost.teaser}</p>
                                        <a href="${thisBlogPost.httpUrl}" class="description-xl readmore"><img src="${templateHttp}dest/img/arrow_blue_right.svg">${window.language.readAnArticle}</a>
                                    </div>
                                </div>`;
    
    
                blogPosts.splice(0, 1);
    
    
            }
    
    
            resultHtml += `</div>`;
    
    
    
        }
    }

    function showInsta() {
        if (instagramPosts.length) {

            resultHtml += `<div class="social-preview-box instagram">`;
    
            for(var i=0; i<instagramPosts.length; i++) {

                var postCreated = parseInt(instagramPosts[i].created);
                var postDate = new Date(1000*postCreated);
                var caption = instagramPosts[0].caption ? `<p class="description blog-content">${instagramPosts[0].caption.text}</p>` : '';

                if (i==1) break;
    
                resultHtml +=   `<div class="social-info">
                                    <a href="${instagramPosts[0].link}"class="social-header" style="background-image: url('${instagramPosts[0].low_resolution}')">
                                        <div class="social-time float-right">
                                            <img src="${templateHttp}dest/img/clock_white.svg" alt="" class="clock">
                                            <p class="description">${ displayDate(postDate).split(' ').join('-') }</p>
                                        </div>
                                    </a>
                                    <div class="social-content">
                                        <div class="author">
                                            <img src="${instagramPosts[0].profileImg}" class="avatar" alt="">
                                            <a href="${instagramPosts[0].link}" class="description-xl">${instagramPosts[0].fullName}</a>
                                        </div>
                                        ${caption}
                                    </div>
                                </div>`;

                instagramPosts.splice(0, 1);
    
    
            }
    
    
            resultHtml += `</div>`;

        }
    }


    function showFacebook() {
        if (facebookPosts.length) {

            resultHtml += `<div class="social-preview-box facebook">`;
    
            for(var i=0; i<facebookPosts.length; i++) {
    
                if (i==4) break;


                var picture = facebookPosts[i]['full_picture'] ?  facebookPosts[i]['full_picture'] : '';
                var pictureElement = picture ? `<img src="${picture}" />` : '';

                var story = facebookPosts[i]['story'] ?  facebookPosts[i]['story'] : '' ;
                var action_link = facebookPosts[i]['actions'] && facebookPosts[i]['actions'][0]  && facebookPosts[i]['actions'][0]['link'] ?  facebookPosts[i]['actions'][0]['link'] : '' ;

    
                resultHtml +=   `<div class="social-info">
                                    <div class="social-header clearfix">
                                        <p class="description float-left"><a target="_blank" href="${action_link}">${facebookPosts[i].from.name}</a></p>
                                        <div class="social-time float-right">
                                            <img src="${templateHttp}dest/img/clock_gray.svg" alt="" class="clock">
                                            <p class="description">${displayDate(new Date(facebookPosts[i].created_time.date))}</p>
                                        </div>
                                    </div>
                                    
                                    <div class="content">
                                        <a target="_blank"  href="${action_link}" class="description-xl fb-title">${story}</a>
                                        <p class="description">${facebookPosts[i].message}</p>
                                        ${pictureElement}
                                    </div>
                                    <a href="${action_link}" target="_blank" class="fb-link">
                                        <img src="${templateHttp}dest/img/fb_in_social.svg" alt="" class="">
                                        Zobacz na Facebook'u
                                    </a>
                                </div>`;
    
    
    
    
            }

            
            facebookPosts.splice(0, 3);

            // debugger;
    
    
            resultHtml += `<div class="social-info link-to-facebook">
                                <a href="https://www.facebook.com/dudacars.legends" target="_blank" class="check_out_in_social">
                                    <img src="${templateHttp}dest/img/fb_in_social.svg" alt="" class="icon_checkout">
                                   Zobacz nas na Facebook
                                </a>
                            </div>
                        </div>`;
    
        }
    }


    function showYoutube() {
        if (youtubeVideos.length) {

            resultHtml += `<div class="social-preview-box youtube">`;
    
            for(var i=0; i<youtubeVideos.length; i++) {

                if (i==1) break;

                var dateYt = new Date(youtubeVideos[0].created);
    
                resultHtml +=   `<div class="social-info">
                                    <a href="https://youtube.com/watch?v=${youtubeVideos[0].videoId}">
                                        <div class="social-header" style="background-image: url('${youtubeVideos[0].thumbnail}')">
                                            <div class="social-time float-right">
                                                <img src="${templateHttp}dest/img/clock_white.svg" alt="" class="clock">
                                                <p class="description">${displayDate(dateYt)}</p>
                                            </div>
                                            <img src="${templateHttp}dest/img/youtube_play_btn.svg" alt="" class="yt-btn">
                                        </div>
                                    </a>
                                    <div class="social-content">
                                        <a href="https://youtube.com/watch?v=${youtubeVideos[0].videoId}" class="description-xl blog-title">${youtubeVideos[0].title}</a>
                                        <p class="description blog-content">${youtubeVideos[0].description}</p>
                                        <a href="https://youtube.com/watch?v=${youtubeVideos[0].videoId}" class="check_out_in_social">
                                            <img src="${templateHttp}dest/img/yt_in_social.svg" alt="" class="icon_checkout">
                                            <p class="description-xl">${window.language.watchOnYoutube}</p>
                                        </a>
                                    </div>
                                </div>`;

                youtubeVideos.splice(0, 1);
    
    
            }
    
    
            resultHtml += `</div>`;
    
    
    
        }
    }

    showTwitter();
    showBlog();
    showInsta();
    showYoutube();
    showInsta();
    showBlog();
    showYoutube();
    showBlog();
    showFacebook();

    $(resultHtml).appendTo('#socialblockResult');

    if ( (youtubeVideos.length + instagramPosts.length + blogPosts.length + twitterPosts.length) > 0 ) {

        // usun sekcje btn
        $('#loadMoreSocialsSection').remove();

        $(`<div class="row load-morebtn" id="loadMoreSocialsSection">
            <a href="#" class="btn btn--white btn--large btn--ico" id="loadMoreSocialsPlease">${window.loadMore}</a>
        </div>`).insertAfter('.loading-socialblock');

        App.loadMoreSocialsPlease();
        // dodaj btn

    } else {
        // usun btn na amen
        $('#loadMoreSocialsSection').remove();
    }

}

App.loadMoreSocialsPlease = () => {

    $('#loadMoreSocialsPlease').click(function(e) {
        e.preventDefault();

        App.displaySocialPosts();

    })

}