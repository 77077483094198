import { createContact } from "./salesmanago";

var App = window['App'] = window['App'] || {};
App.serviceForm = () => {
    // formularz "Nie znalazłeś nic dla siebie?"
    if ( $('#serviceForm').length ) {

        var $form = $('#serviceForm');
        $form.validate({
            rules: {
                email: {
                    required: true,
                    emailCheck: true
                },
                mobile: {
                    required: true,
                    phonePL: true
                },
                name: {
                    required: true,
                    lettersonly: true
                },
                msg: {
                    required: true,
                    minlength: 15,
                    maxlength: 5000
                }
            },
            messages: {
                email: {
                    required: window.language.requiredEmail
                },
                mobile: {
                    required: window.language.requiredPhone,
                    phonePL: window.language.correctPhone
                },
                name: {
                    required: window.language.requiredNameSurname,
                },
                msg: {
                    required: window.language.requiredField,
                    minlength: window.language.minLenght15Field,
                    maxlength: window.language.maxLenght5000Field
                }
            },
            errorPlacement: function(error, element) {
                if (element.attr("id") == "form_agree" || element.attr("id") == "form_agree" || element.attr("id") == "form_agree") {
                    error.appendTo("#serviceForm .agreement1");
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {

                var formUrl = window.rootHomepage + "api/?type=serviceform";
                $('#serviceForm .error-message').html('');

                GTMUtils.addDataLayer({
                    'event': 'custom',
                    'category': 'Wysłanie formularza Serwis',
                    'location': window.location.pathname,
                    'sourcePage': window.location.href,
                    // 'type': e.target.tagName,
                    // 'value': e.target.href 
                });
                
                FBPixel.track('Lead',{
                    'content_category': 'Wysłanie formularza Serwis',
                });

                const { name, email, mobile } = $('#serviceForm').serializeObject();
                createContact({
                  name,
                  email,
                  phone: mobile
                });
                
                $.ajax({
                    type: 'POST',
                    url: formUrl,
                    // cache: false,
                    data: $("#serviceForm").serialize(),
                    // contentType: false,
                    // processData: false,
                    success: function(data){
                        if(data.success){
                            $('#serviceSuccess').show();
                            $('#serviceForm .form-columns').hide();
                        } else {
                            //
                        }
                    },
                    error: function(err){
                      $('#serviceForm .error-message').html(window.language.submitFormFailed);
                      // console.log(err);
                        ///
                    }
                })
                // console.warn('Wysłano formularz');
            },
            invalidHandler: function(form) {
              // console.error(this.settings);

                // console.error('Wystapil blad przy walidacji');
            }
        }).settings.ignore = [];

    }
}