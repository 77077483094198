var App = window['App'] = window['App'] || {};
// FORMULARZ NIEUŻYWANY NA STRONIE
// BRAK PODPIĘCIA Z API
// BARTEK 5.11.2020
App.partsForm = () => {
    // formularz "Nie znalazłeś nic dla siebie?"
    if ( $('#partsForm').length ) {

        var $form = $('#partsForm');

        $form.validate({
            rules: {
                client_email: {
                    required: true,
                    emailCheck: true
                },
                client_mobile: {
                    required: true,
                    phonePL: true
                },
                client_name: {
                    required: true,
                    lettersonly: true
                },
                client_msg: {
                    required: true,
                    minlength: 15,
                    maxlength: 5000
                },
                agree1: "required"
            },
            messages: {
                client_email: {
                    required: window.language.requiredEmail
                },
                client_mobile: {
                    required: window.language.requiredPhone,
                    phonePL: window.language.correctPhone
                },
                client_name: {
                    required: window.language.requiredNameSurname,
                },
                agree1: window.language.requiredField,
                client_msg: {
                    required: window.language.requiredField,
                    minlength: window.language.minLenght15Field,
                    maxlength: window.language.maxLenght5000Field
                },
            },
            errorPlacement: function(error, element) {
                if (element.attr("id") == "agree1") {
                    error.appendTo("#partsForm .agreement1");
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {

                console.warn('Wysłano formularz');
            },
            invalidHandler: function(form) {

                console.error('Wystapil blad przy walidacji');
            }
        }).settings.ignore = [];

    }
}