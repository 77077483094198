var App = window['App'] = window['App'] || {};
App.showSubmenu = (e) => {
    const menuLinks = document.querySelectorAll('.main-menu--element');

    menuLinks.forEach( (el,index) => {
        const hasSubmenu = el.querySelectorAll('.main-submenu').length;

        if (hasSubmenu) {
            const thisSubmenu = el.querySelector('.main-submenu');

            el.addEventListener('click', () => {
                const allSubmenus = document.querySelectorAll('.main-submenu');


                for (let i = 0; i< allSubmenus.length; i++) {

                    if (allSubmenus[i].classList.contains('active') && thisSubmenu != allSubmenus[i]) {
                        allSubmenus[i].classList.remove('active');
                    }
                }

                thisSubmenu.classList.toggle('active');
            });

        }
            return false

    });
}

App.closeSubmenuses = () => {
    // funkcja zwraca true gdy submenu jest otwarte (ma klase active)
    // funkcja zwraca false gdy submenu jest zamkniete (nie ma klasy active)
    const allSubmenus = document.querySelectorAll('.main-submenu');

    for (let i = 0; i< allSubmenus.length; i++) {

        if (allSubmenus[i].classList.contains('active')) {
            allSubmenus[i].classList.remove('active');
        }
    }

}

App.showResponsiveMenu = (e) => {
    const hamburgerBtn = document.getElementById('jsHamburger');
    const mainMenu = document.getElementById('jsMainMenu');
    const shadowLayer = document.getElementById('jsLayer');

    hamburgerBtn.addEventListener('click', (e) => {
        e.preventDefault();
        hamburgerBtn.classList.toggle('is-active');
        mainMenu.classList.toggle('mobile-menu-active');
        shadowLayer.classList.toggle('active');
    });
}

App.resizeRwdOpen = () => {
    // close rwd menu when someone resize the window and it's open
    const hamburgerBtn = document.getElementById('jsHamburger');
    const mainMenu = document.getElementById('jsMainMenu');
    const classListMenu = Array.from(mainMenu.classList);
    const windowWidth = window.innerWidth;
    const menuIsOpen = classListMenu.includes('mobile-menu-active');
    const shadowLayer = document.getElementById('jsLayer');

    if (menuIsOpen && windowWidth > 990) {
        hamburgerBtn.classList.remove('is-active');
        mainMenu.classList.remove('mobile-menu-active');
        shadowLayer.classList.remove('active');
    }

    return false;

}

App.hideSubmenuOnResize = () => {
    const windowWidth = window.innerWidth;

    if(windowWidth < 991 && windowWidth > 989) {
        App.closeSubmenuses();
        App.closeOpenLanguageMenu();
    }
}

App.hideSubmenuOnClickOutside = () => {

    if (window.innerWidth > 990) {

        $('html').click(function(e) {

            if ( $(e.target).closest('.main-menu').length === 0) {

                $('.main-submenu.active').removeClass('active');

            }

            if ( $(e.target).closest('.language-menu').length === 0) {

                App.closeOpenLanguageMenu();

            }


        });

    }
}

// jeśli jakiś link w menu jest # nie otwieraj tego linku
App.preventHashMenuLinks = () => {

    $('.nav-container .main-menu__link').on('click', function(e) {

        if ( $(this).attr('href') == "#") {
            e.preventDefault();
        }
    })

}