import { FBPixel, GTMUtils } from "./main";
import { createContact } from "./salesmanago";

var App = window['App'] = window['App'] || {};
App.contactForm = () => {
    // formularz "Nie znalazłeś nic dla siebie?"
    if ( $('#contactForm').length ) {
        if(window.isPromoPage){
            $('#isPromoForm').val('1');
        }
        var $form = $('#contactForm');
        $form.validate({
            rules: {
                email: {
                    required: true,
                    emailCheck: true
                },
                phone: {
                    required: true,
                    phonePL: window.userLanguageId === 'PL' ? true : false,
                    digitsonly: true
                },
                name: {
                    required: true,
                    lettersonly: true
                }
            },
            messages: {
                email: {
                    required: window.language.requiredEmail
                },
                phone: {
                    required: window.language.requiredPhone,
                    phonePL: window.language.correctPhone,
                    digitsonly: window.language.onlyDigits
                },
                name: {
                    required: window.language.requiredNameSurname,
                }
            },
            errorPlacement: function(error, element) {
                if (element.attr("id") == "form_agree1" || element.attr("id") == "form_agree2" || element.attr("id") == "form_agree3") {
                    error.appendTo("#contactForm .agreement1");
                } else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {

                // console.warn('Wysłano formularz');

                App.lockPopupForm();
                $('#contactForm .error-message').html('');

                    var formUrl = window.rootHomepage + "api/?type=askaboutcontact";



                    var carName = $('#contactForm [name=carName]').val() || 'empty';
                    var path = $('#contactForm [name=path]').val() || 'empty';


                        
                    GTMUtils.addDataLayer({
                        'event': 'custom',
                        'category': 'Wysłanie formularza Kontaktowego',
                        'location': window.location.pathname,
                        'sourcePage': window.location.href,
                        // 'type': e.target.tagName,
                        'value': carName +' -> '+ path, 
                    });
                    
                    FBPixel.track('Lead',{
                        'content_category': 'Wysłanie formularza Kontaktowego',
                        'content_name': carName, 
                    });


                    const { name, email, phone } = $("#contactForm").serializeObject();
                    createContact({
                      name,
                      email,
                      phone
                    });
                    
                    $.ajax({
                        type: 'POST',
                        url: formUrl,
                        // cache: false,
                        data: $("#contactForm").serialize(),
                        // contentType: false,
                        // processData: false, 
                        success: function(data){
                            App.unlockPopupForm();
                            if(data.success){
                                $('[data-popupForm], #contactForm.contact').hide();
                                $('[data-popupFormSuccess], #serviceSuccess').show();
                                $('.popup.active').addClass('success');
                            } else {
                                $('#contactForm .error-message').html(data.error.message);
                            }
                        },
                        error: function(err){
                            App.unlockPopupForm();
                            $('#contactForm .error-message').html(window.language.submitFormFailed);
                        }
                    })

            },
            invalidHandler: function(form) {

                // console.error('Wystapil blad przy walidacji');
            }
        }).settings.ignore = [];

    }
}

App.lockPopupForm = () => {
    $('#contactForm .circle-loader').show();
    $('#contactForm [data-submitbtn]').attr('disabled', true);
}

App.unlockPopupForm = () => {
    $('#contactForm .circle-loader').hide();
    $('#contactForm [data-submitbtn]').attr('disabled', false);
}