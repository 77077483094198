var App = window['App'] = window['App'] || {};
// inicjalizacja galerii - widok szczegółowy oferty
// App.initSliderOnCarPage = () => {
//     if ( $('.car-photo-slider').length ) {
//         var galleryTop = new Swiper('.car-photo-gallery', {
//             spaceBetween: 0,
//             loop: true,
//             slidesPerView: 1,
//         });
//         var galleryThumbs = new Swiper('.car-photo-thumbs', {
//             spaceBetween: 0,
//             navigation: {
//                 nextEl: '.swiper-button-next',
//                 prevEl: '.swiper-button-prev',
//             },
//             loop: true,
//             centeredSlides: true,
//             slidesPerView: 4,
//             touchRatio: 0.2,
//             slideToClickedSlide: true
//           });

//         galleryTop.on('slideChange', function() {
//             // galleryThumbs.slideToLoop(galleryTop.realIndex, 400);
//             galleryThumbs.slideToLoop(galleryTop.realIndex);
//             // // galleryThumbs.activeIndex = galleryTop.activeIndex;
//         });

//         galleryThumbs.on('slideChange', function() {
//             // console.log(galleryThumbs.realIndex);
//             galleryTop.slideToLoop(galleryThumbs.realIndex, 400);
//         });

//     }
// }


App.initSimpleSwiper = () => {
    if ( $('.simple-swiper').length ) {
        $('.simple-swiper').each(function(i, el){

            var simpleSwiper = new Swiper(el, {
                spaceBetween: 0,
                loop: true,
                slidesPerView: 1,
                autoplay: {
                    delay:2000,
                    disableOnInteraction: false
                },
            });

            // debugger;
        });
       

        setTimeout(function(){
            window.dispatchEvent(new Event('resize'));
        },2000);

    }
}


App.initSliderOnCarPage = () => {
    if ( $('.car-photo-slider').length ) {
        var galleryTop = new Swiper('.car-photo-gallery', {
            spaceBetween: 0,
            loop: true,
            slidesPerView: 1,
        });
        var galleryThumbs = new Swiper('.car-photo-thumbs', {
            spaceBetween: 0,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            loop: true,
            centeredSlides: true,
            slidesPerView: 6,
            touchRatio: 0.2,
            slideToClickedSlide: true,
            breakpoints: {
                990: {
                    slidesPerView: 4
                },
                768: {
                    slidesPerView: 6
                },
                600: {
                    slidesPerView: 5
                },
                480: {
                    slidesPerView: 3
                }
            }
          });

        galleryTop.on('slideChange', function() {
            // galleryThumbs.slideToLoop(galleryTop.realIndex, 400);
            galleryThumbs.slideToLoop(galleryTop.realIndex);
            // // galleryThumbs.activeIndex = galleryTop.activeIndex;
        });

        galleryThumbs.on('slideChange', function() {
            // console.log(galleryThumbs.realIndex);
            galleryTop.slideToLoop(galleryThumbs.realIndex, 400);
        });

    }
}