var App = window['App'] = window['App'] || {};
App.mainSearchBarVisible = () => {
    const searchBtn = document.getElementById('jsMainSearchBtn');
    const searchBar = document.getElementById('jsSearchBar');
    const searchLupeBtn = searchBtn.querySelector('.nav-search-lupe');

    searchBtn.addEventListener('click', (e) => {
        const searchBarClassList = Array.from(searchBar.classList);
        if (searchBarClassList.includes('active')) {
            // usun klase bo taka istnieje
            searchBar.classList.remove('active');
            searchLupeBtn.classList.remove('active');
        } else {
            // dodaj klase bo taka nie istnieje
            searchBar.classList.add('active');
            searchLupeBtn.classList.add('active');
            searchBar.querySelector('input').focus();
            App.closeSubmenuses();
        }
    })

}

App.resizeSearchBar = () => {
    const searchBtn = document.getElementById('jsMainSearchBtn');
    const searchBar = document.getElementById('jsSearchBar');
    const windowWidth = window.innerWidth;
    const searchBarClassList = Array.from(searchBar.classList);
    const searchIsOpen = searchBarClassList.includes('active');
    const searchLupeBtn = searchBtn.querySelector('.nav-search-lupe');

    if(searchIsOpen && windowWidth < 991) {
        App.closeSubmenuses();
        searchBar.classList.remove('active');
        searchLupeBtn.classList.remove('active');
    }

    return false

}

App.mainPageSearch = () => {

    if ( $('#pageSearchRwd').length || $('#pageSearchDesktop').length ) {

        $('#pageSearchRwd').on('submit', function(e) {
            e.preventDefault();

            var phrase = $(this).serialize().cleanPL();
            var frazaPL = $(this).find('input[name=phrase]').val();

            $('#jsHamburger').click();

            App.searchOnWebsite(phrase, frazaPL);

            $(this).find('input[name=phrase]').val('');

        })

        $('#pageSearchDesktop').on('submit', function(e) {
            e.preventDefault();

            $('.search-bar.active').removeClass('active');
            $('.nav-search-lupe.active').removeClass('active');

            var phrase = $(this).serialize().cleanPL();
            var frazaPL = $(this).find('input[name=phrase]').val();

            App.searchOnWebsite(phrase, frazaPL);

            $(this).find('input[name=phrase]').val('');

        })


    }
}

App.searchOnWebsite = (phrase, frazaPL) => {

    $('.loading').show();

    $.get(`${window.rootHomepage}api/?type=search&${phrase}`, function(data, status) {


    }).done(function(data) {

        var cars = data.cars;
        var all = data.all;
        // var services = data.services;

        App.displayPageSearchResults(cars, all, frazaPL);

        // console.log(data);

        $('.loading').hide();

    })
}

App.displayPageSearchResults = (cars, all, phrase) => {

    // console.log(cars);
    // console.log(all);

    $('#mainSearchResults').slideDown();
    $('#mainSearchResults').html('');
    var resultHtml = '';
    if (!cars.length && !all.length) {

        resultHtml += `<span class="close-search">&times;</span><div class="row columns">
                        <p class="section-title hl hl--black hl--min">${window.language.noSearchResults} <span id="searchedText">${phrase}</span></p>
                    </div>`;
    } else {



        resultHtml += `<span class="close-search">&times;</span><div class="row columns search-result-container">`;

        // wyszukiwanie po art.

        if (all.length) {

            var news = all;

            resultHtml += `<div>
                        <p class="section-title hl hl--black hl--min">${window.searchResults} <span id="searchedText">${phrase}</span></p>
                    </div>`;

            for (var i=0; i<news.length; i++) {

                var category = news[i].parentTitle ? `<p class="description">${news[i].parentTitle}</p>` : '';
                var artTitle = news[i].title ? `<p class="smaller-section-title">${news[i].title}</p>` : '';
                var descArt = news[i].content ? `<div class="description art-content">${news[i].content.length>300 ? news[i].content.slice(0, 280) + '...' : news[i].content}</div>` : '';
                var linkMore = news[i].url ? `<a href="${news[i].url}" class="link-more">${window.language.readMore}</a>` : '';

                var parentLink = linkMore ? linkMore : (news[i].parentUrl ? `<a href="${news[i].parentUrl}" class="link-more">${window.language.searchLink}</a>` : '');

                resultHtml += `<div class="founded-news medium-8">
                                    ${category}
                                    ${artTitle}
                                    ${descArt}
                                    ${parentLink}
                                </div>`;


            }


        }

        if (cars.length) {

            resultHtml += `<div class="founded-cars">
                            <div class="founded-cars-header">
                                <p class="section-title hl hl--black hl--min">${window.language.foundCars} ${phrase} </p>
                            </div>
                        </div><div class="car-blocks-list">
                        <div class="row medium-row car-blocks-container"><div class="car-blocks">`;

            for (var i=0; i<cars.length; i++) {

                    var data = cars;

                    // wyswietl HTML dla samochodu
                    var carLink = data[i].httpUrl;
                    var carImg = data[i].image[0].url;
                    var carIsSoon = data[i].soon_on_offer;
                    var carPrice = parseInt(data[i].price_gross).toFixed(0);
                    var carName = carIsSoon ? `<p class="car-name">${data[i].title[0]}</p>` : `<a href="${carLink}" class="car-name">${data[i].title[0]}</a>`;

                    var carButtons = carIsSoon ? `<div class="btn-group"><a href="#" class="btn btn--darkblue btn--ico" data-askabout>${window.language.askAbout}</a></div>` : `<div class="btn-group"><a href="#" class="btn btn--darkblue btn--ico" data-askabout>${window.language.askAbout}</a><a href="${carLink}" class="btn btn--outline_onlight btn--ico">${window.language.details}</a></div>`;

                    var carHeaderImg = carIsSoon ? `<div class="img-block-image car-block-img coming-soon-car" style="background-image: url('${carImg}')"><div class="link-layer"><p class="info-link">${window.language.carSoonAvailable}</p></div></div>` : `<a href="${carLink}" class="img-block-image car-block-img" style="background-image: url('${carImg}')"><div class="link-layer"><span class="info-link">${window.language.watchMore}</span></div></a>`;

                    var rocznik = data[i].yearbook ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_calendar.svg" alt="" class="car-icon"><p class="description">${window.language.yearbook} ${data[i].yearbook}</p></div>` : '';

                    var przebieg = data[i].mileage ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_arrow_przebieg.svg" alt="" class="car-icon"><p class="description">${window.language.mileage} ${data[i].mileage} km</p></div>` : '';

                    var silnik = data[i].engine ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_engine.svg" alt="" class="car-icon"><p class="description">${window.language.engine} ${data[i].engine}cm<sup>3</sup></p></div>` : '';

                    var paliwo = data[i].fuel_type ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_fuel.svg" alt="" class="car-icon"><p class="description">${window.language.fuel} ${data[i].fuel_type}</p></div>` : '';

                    var moc = data[i].power ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_wheel.svg" alt="" class="car-icon"><p class="description">${window.language.power} ${data[i].power}</p></div>` : '';

                    var skrzynia = data[i].yearbook ? `<div class="columns icon-container"><img src="${window.templateHttp}dest/img/icon_gears.svg" alt="" class="car-icon"><p class="description">${data[i].transmission}</p></div>` : '';

                    resultHtml +=   `
                        <div class="car-block">
                            ${carHeaderImg}
                            <div class="carinformation-block">
                                <div class="car-desc">
                                    <p class="description">${window.language.priceGross} ${carPrice} ${window.language.pln}</p>
                                    ${carName}
                                </div>
                                <div class="car-icons clearfix large-up-2 zero-collapse">
                                    ${rocznik}
                                    ${przebieg}
                                    ${silnik}
                                    ${paliwo}
                                    ${moc}
                                    ${skrzynia}
                                </div>
                                ${carButtons}
                            </div>
                        </div>`;




            }

            resultHtml += `</div></div></div>`;


        }

        // wyszukiwanie po samochodach


        resultHtml += `</div>`;


    }


    $('#mainSearchResults').html(resultHtml);

    if ( $('.car-blocks-container').length ) {
        $('.car-blocks-container .car-blocks .carinformation-block').matchHeight();


    }

    App.askAboutCar();
    App.makeResultsCloseable();

    $.fn.matchHeight._update();

}

App.makeResultsCloseable = () => {

    $('.search-mainpage .close-search').on('click', function(e) {

        $('#mainSearchResults').slideUp();

    })

}