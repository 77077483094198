var App = window['App'] = window['App'] || {};
// FORMULARZ NIEUŻYWANY NA STRONIE
// BRAK PODPIĘCIA Z API
// BARTEK 5.11.2020
App.sellClassic = () => {

    if ( $('#sellClassic').length ) {

        var $form = $('#sellClassic');

        $('#fileToUpload').on('change', function() {

            if ( $(this).val() ) {

                $(this).removeClass('error');
                $(this).parent().css('border-color', '#c7c7c2');

            } else {
                $(this).parent().css('border-color', 'red');
            }

        })

        $form.validate({
            rules: {
                client_email: {
                    required: true,
                    emailCheck: true
                },
                client_mobile: {
                    required: true,
                    phonePL: true
                },
                client_name: {
                    required: true,
                    lettersonly: true
                },
                agree1: "required",
                car_model: {
                    required: true
                },
                car_year: {
                    required: true,
                    digitsonly: true,
                    minlength: 4,
                    min: 1890,
                    max: new Date().getFullYear()
                },
                car_mileage: {
                    required: true,
                    digitsonly: true
                },
                fileToUpload: {
                    required: true
                }
            },
            messages: {
                client_email: {
                    required: window.language.requiredEmail
                },
                client_mobile: {
                    required: window.language.requiredPhone,
                    phonePL: window.language.correctPhone
                },
                client_name: {
                    required: window.language.requiredNameSurname,
                },
                fileToUpload: {
                    required: window.language.uploadFile,
                },
                agree1: window.language.requiredField,
                car_model: {
                    required: window.language.requiredField
                },
                car_year: {
                    required: window.language.requiredCarYear,
                    minlength: window.language.minLenghtField,
                    min: window.language.minValue1890Field,
                    max: window.language.maxValueIsField + new Date().getFullYear()
                },
                car_mileage: {
                    required: window.language.requiredField,
                    digitsonly: window.language.onlyDigits
                }
            },
            errorPlacement: function(error, element) {
                if (element.attr("id") == "agree1") {
                    error.appendTo("#sellClassic .agreement1");
                } /* else if( element.attr("id") == "fileToUpload" ) {
                    error.insertAfter(".file-loader");
                } */else {
                    error.insertAfter(element);
                }
            },
            submitHandler: function(form) {


                // console.warn('Wysłano formularz');
            },
            invalidHandler: function(form) {

                if ( !$('#fileToUpload').val() ) {

                    $('#fileToUpload').parent().css('border-color', 'red');

                }

                // console.error('Wystapil blad przy walidacji');
            }
        }).settings.ignore = [];

        //onChange input file
        $('#fileToUpload').on('change', function(e) {

            $('.file-loader .file-name').html(window.language.chosenFile+": "+ this.files[0].name)

        })

    }

}