var App = window['App'] = window['App'] || {};
// Zamiana polskich znaków na znaki bez ogonków
String.prototype.cleanPL = function() {
    /*

    */
    return this.replace(/ą/g, 'a').replace(/Ą/g, 'A')
        .replace(/ć/g, 'c').replace(/Ć/g, 'C')
        .replace(/ę/g, 'e').replace(/Ę/g, 'E')
        .replace(/ł/g, 'l').replace(/Ł/g, 'L')
        .replace(/ń/g, 'n').replace(/Ń/g, 'N')
        .replace(/ó/g, 'o').replace(/Ó/g, 'O')
        .replace(/ś/g, 's').replace(/Ś/g, 'S')
        .replace(/ż/g, 'z').replace(/Ż/g, 'Z')
        .replace(/ź/g, 'z').replace(/Ź/g, 'Z')
        .replace(/%C4%85/g, 'a').replace(/%C4%87/g, 'c')
        .replace(/%C4%99/g, 'e').replace(/%C5%82/g, 'l')
        .replace(/%C5%84/g, 'n').replace(/%C3%B3/g, 'o')
        .replace(/%C5%9B/g, 's').replace(/%C5%BA/g, 'z')
        .replace(/%C5%BC/g, 'z').replace(/%C4%84/g, 'A')
        .replace(/%C4%86/g, 'C').replace(/%C4%98/g, 'E')
        .replace(/%C5%81/g, 'L').replace(/%C5%83/g, 'N')
        .replace(/%C3%93/g, 'O').replace(/%C5%9A/g, 'S')
        .replace(/%C5%B9/g, 'Z').replace(/%C5%BB/g, 'Z');
}

export function displayDate(date) {

    var today = new Date();
    var articleDate = date;

    var timeDiff = today.getTime() - articleDate.getTime();
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    diffDays = Math.abs(diffDays-1);

    if (diffDays == 0) {
        return window.language.today
    } else if (diffDays == 1) {
        return window.language.yesterday
    } else if (diffDays == 2) {
        return window.language.twoDaysAgo.toUpperCase()
    } else if (diffDays == 3) {
        return window.language.threeDaysAgo.toUpperCase()
    } else if (diffDays == 7) {
        return window.language.weekAgo
    } else {
        return date.toLocaleDateString('pl-PL', {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
            }).toUpperCase();
    }

}

/**
 * Tworzy slide range z inputa
 * @param {String} el Element DOM
 * @param {Number} start Wartość startowa
 * @param {Number} end Wartość końcowa
 * @param {Number} step Krok
 * @param {String} nameForMin DOM[name] dla min
 * @param {String} nameForMax DOM[name] dla max
 * @param {Boolean} currency Jeśli jest true to kwoty min i max będą zapisane jako walutowe
 */
export function createSlideRange(el, start, end, step, nameForMin, nameForMax, currency) {
    // Inicjalizacja zmiennych
    var p_slideRange  = document.getElementById(el);
    var p_slideMin = parseInt(p_slideRange.dataset.minyear);
    var p_slideMax = parseInt(p_slideRange.dataset.maxyear);
    var p_minTextBefore = $(p_slideRange).prev('.min-range');
    var p_maxTextAfter = $(p_slideRange).next('.max-range');


    var min = start ? start : p_slideMin;
    var max = end ? end : p_slideMax;

    // insert hidden input for minimal value
    $(p_slideRange).append(`<input type="hidden" name="${nameForMin || 0}" value="${min || 0}">`)
    // insert hidden input for maximal value
    $(p_slideRange).append(`<input type="hidden" name="${nameForMax || 0}" value="${max || 0}">`)

    // Inicjalizacja Slidera
    noUiSlider.create(p_slideRange, {
        start: [min, max],
        connect: true,
        range: {
            'min': p_slideMin,
            'max': p_slideMax
        },
        step: step
    });

    // Pokazywanie zmian po dragNdrop sliderange'a
    p_slideRange.noUiSlider.on('update', function( values, handle ) {
        // aktualizuj wartości przy range'u
        if(currency){
            // this.updateOptions();
            p_minTextBefore.html(parseInt(values[0]).toLocaleString({style: 'currency'}) + ' PLN');
            p_maxTextAfter.html(parseInt(values[1]).toLocaleString({style: 'currency'}) + ' PLN');
        } else {
            p_minTextBefore.html(parseInt(values[0]));
            p_maxTextAfter.html(parseInt(values[1]));
        }
        // aktualizuj hidden inputy
        $(`[name=${nameForMin}]`).val(parseInt(values[0]));
        $(`[name=${nameForMax}]`).val(parseInt(values[1]));
    });

}

// funkcja animuje scroll do wybranego miejsca (wymagany atrybut data-scrollto="klasa badz id" np. data-scrollto=".header")
App.scrollToTargetBtns = () => {

    if ( $('[data-scrollto]').length ) {

        $('[data-scrollto]').on('click', function(e) {

            if ( !!$('[data-scrollto]')[0].dataset.scrollto ) {
                e.preventDefault();

                var thistarget = this.dataset.scrollto;
                var positionOfTarget = $(thistarget).offset().top;
    
                $("html, body").animate({ scrollTop: positionOfTarget-30 }, 500);
            }


        })

    }

}

App.openGalleryOnLink = () => {

    // if ( $('.car-galleries').length ) {

    //     $('.gallery-desc').on('click', function(e) {
    //         var imageThumbnail = $(this).prev('a');
    //         e.preventDefault();
    //         $(imageThumbnail).click();

    //     });

    // }

}

window.addEventListener("resize", () => {
    //funkcje przy resize okna

    // zamyka/chowa rwd menu gdy jest otwarte
    App.resizeRwdOpen();
    // zamyka/chowa wyszukiwarke gdy jest otwarta
    App.resizeSearchBar();
    // zamyka/chowa submenu gdy jest otwarte
    App.hideSubmenuOnResize();

});