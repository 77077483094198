var App = window['App'] = window['App'] || {};
// Materiały marketingowe na karcie modelu
App.historySlider = () => {
    // slider materiałów promocyjnych w karcie modelu

    if ( document.querySelectorAll('.history-slider-module').length ) {

        var historySlider = new Swiper('.history-slider', {
            slidesPerView: 1,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            }
        });

    }
}