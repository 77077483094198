var App = window['App'] = window['App'] || {};
App.showChartOnModel = () => {

    const chartModule = document.querySelectorAll('.chart-module');

    if (chartModule.length) {

        if(window.CSVfile) {
            Papa.parse(window.CSVfile, {
                encoding: "CP1250",
                download: true,
                delimiter: "",	// auto-detect
                newline: "",	// auto-detect
                quoteChar: '"',
                header: false,
                skipEmptyLines: true,
                    complete: function(results) {
                        // cale wyniki
                        var data = results.data;
                        // pierwszy wiersz
                        var from = results.data.slice(0,1);
                        // drugi wiersz
                        var to = results.data.slice(1,2);
                        // reszta
                        var years = results.data.slice(2,results.data.length);
                        // Mapa google - kontaktowa

                        App.createChartForCar(data, from, to, years);

                        // wykonaj funkcje rysowania wykresu
                    }
                });
        }
    }
}

App.createChartForCar = function(data, from, to, years) {

    var labels = new Array(years.length);
    for (var i=0; i<labels.length; i++) {
        labels[i] = "";
    }
    labels[0] = from[0][0];
    labels[labels.length-1] = to[0][0];


    var newData = new Array(years.length);
    for (var j=0; j<labels.length; j++) {
        newData[j] = years[j][0];
    }

    var lineChartData = {
		labels: labels,
		datasets: [{
				label: "SES 3.4",
				fillColor: "rgba(255,255,255,0)",
				strokeColor: "#003366",
				pointColor: "#003366",
				pointStrokeColor: "#fff",
				pointHighlightFill: "#fff",
				pointHighlightStroke: "#003366",
				data: newData
		}]

    }

    Chart.defaults.global.responsive = true;

    var ctx = document.getElementById("chartCanvas").getContext("2d");
		window.myLine = new Chart(ctx).Line(lineChartData, {
				responsive: true
		});



}