var App = window['App'] = window['App'] || {};


import './_functions.js';
import './_menus.js';
import './_popups.js';
import './_top-searchbar.js';
import './_charts.js';
import './_language.js';
import './_controlHeight.js';
import './_sliderCarDetails.js';
import './_modelSlider.js';
import './_historySlider.js';
import './_homeSlider.js';
import './_socialmedia.js';
import './_typoCarList.js';
import './_sliderHeader.js';

import './salesmanago.js';

import './_validateMethods.js';

import './_filtering.js';
import './_filterNews.js';
import './_filterCars.js';

import './_formNewsletter.js';
import './_formService.js';
import './_formContact.js';
import './_formAskAboutCarPopup.js';
import './_formParts.js';
import './_formSellClassic.js';
import './_formOtherCar.js';

import './_cookies.js';

export const GTMUtils = window['GTMUtils'] = {
    addDataLayer: function(obj){
        if( window['dataLayer'] ){
            window.dataLayer.push( obj );
        }
    }
};

export const FBPixel = window['FBPixel'] = {
    track: function(event, data){
        if( window['fbq'] ){
            fbq('track',event, data)
        }
    },
    trackCustom: function(event, data){
        if( window['fbq'] ){
          fbq('trackCustom', event, data);
        }
    }
};

$(document).on('click','[data-gtm-button-details]', function(e){
    // console.log(e);
    // e.preventDefault();
    // debugger;
    GTMUtils.addDataLayer({
        'event': 'custom',
        'category': 'Klik w przycisk "Szczegóły" -> '+ (e.target.href || ''),
        'location': window.location.pathname,
        'sourcePage': window.location.href,
        // 'type': e.target.tagName,
        'value': e.target.href 
    });
     
    FBPixel.trackCustom('Click',{
        'description': 'Klik w przycisk "Szczegóły"',
        'link': (e.target.href || ''),
    });
});


// jQuery
$(function() {

    // pokazuj i zamykaj wyszukiwarke
    App.mainSearchBarVisible();

    // pokaz responsywne menu po klikniecu na hamburger
    App.showResponsiveMenu();

    // pokaz submenu dla menu desktopowego
    App.showSubmenu();

    App.validateMethods();
    App.sellClassic();
    App.otherCarForm();
    App.partsForm();
    App.newsletterForm();
    App.askAboutCarForm();
    App.serviceForm();
    App.contactForm();

    // Animacja dla kliknięcia pokaż podobne modele w widoku szczegółowym pojazdu
    App.checkSimilar();

    // Okienko cookies
    App.showCookie();

    App.filterForm();
    App.filterNews();

    // mainPage Search

    App.mainPageSearch();

    // socialmedia

    App.loadSocialMediaSection();

    // kazdy element z klasa .swipebox bedzie galeria SwipeBoxa
    $( '.swipebox' ).swipebox({
        hideBarsDelay: false,
        removeBarsOnMobile : false,
        useSVG : false,
        afterOpen: function() {
            $('#swipebox-close').on('click touchend',function(e){ e.preventDefault(); e.stopPropagation(); });}
    });

    App.changeLanguage();

    // slider zdjęciowy na stronie ofertowej
    App.initSliderOnCarPage();

    // klikniecie swipebox link
    App.openGalleryOnLink();

    // Moduł filtrowania
    App.filteringModuleTabs();

    // Typoszeregi zwijalna lista
    App.typoCarsShowList();

    // Slider na stronie głównej
    App.homeSlider();
    App.initSimpleSwiper();

    // Slider materiałów promocyjnych na karcie modelu
    App.historySlider();

    // Galeria na stronie karty modelu
    App.modelSliderGallery();

    // Header slider na stronie
    App.initSliderHeader();

    // kontrola nad wysokoscia elementow w rzedzie dzieki matchHeight.js
    App.controlHeight();

    // scroll do wybranego miejsca
    App.scrollToTargetBtns();

    // nie otwieraj linkow w menu z #
    App.preventHashMenuLinks();

    // slider zdjeciowy jako galeria w widoku szczegolowym pojazdu
    App.initSliderOnCarPage();

    // ukryj menu gdy user kliknie poza menu
    App.hideSubmenuOnClickOutside();

    // rysuj wykres z CSV
    App.showChartOnModel();

    // Btn zapytaj o samochod
    App.askAboutCar();

    //hide popup
    App.hidePopupClickOutside();

        // footer
        $('.footer-grid-title').on('click', function(e){
          e.stopPropagation();
    
          const $isChild = $(e.target).hasClass('footer-grid-title-icon') || $(e.target).hasClass('hl');
          const $element = $isChild ? $(e.target).parent() : $(e.target);
    
          if( $element.hasClass('active') ){
            $($element).next().slideUp();
            $($element).removeClass('active');
          } else {
            $('.footer-grid-title').removeClass('active');
            $($element).addClass('active');
            $('.footer-anchors').slideUp();
            $($element).next().slideDown();
          }
    
        });
    
        $(window).on('resize', function(e){
          console.log('resize');
          if(window.innerWidth > 767){
            // display all footer links
            $('.footer-anchors').slideDown();
          } else {
            // hide all footer links
            $('.footer-anchors').slideUp();
          }
        })

});