var App = window['App'] = window['App'] || {};
const OWNER_MAIL = 'marketing@duda-cars.mercedes-benz.pl';
const LS_ID = 'sm_mail_legends';

export const salesManagoExtEvent = (evType, email) => {
  const date = new Date();
  sm('extEvent', {
    owner: OWNER_MAIL,
    email,
    contactExtEventType: evType,
    date: date.getTime()
  });
}

if (localStorage.getItem(LS_ID)) {
  // VISIT event for SALESMANAGO if client exist
  salesManagoExtEvent('VISIT', localStorage.getItem(LS_ID))
}

export const createContact = async(contact = {}) => {

  const postURL = window.smApiUrl;
  const formData = new FormData();

  for( let key in contact ) {
    formData.append(key, contact[key]);
  }

  const res = await fetch(postURL, {
    method: 'POST',
    body: formData
  }).then(r => r.json());

  if (res.success) {
    localStorage.setItem(LS_ID, contact.email);
  }

}

// createContact({ email: 'testowy_email@o2.pl' });