var App = window['App'] = window['App'] || {};
App.modelSliderGallery = () => {

    if ( document.querySelectorAll('.model-gallery').length ) {

        var slidesQty = document.querySelectorAll('.model-gallery .swiper-slide').length;
        // console.warn(slidesQty);

        var modelSlider = new Swiper('.modelSlider', {
            slidesPerView: 6,
            loop: false,
            breakpoints: {
                768: {
                    slidesPerView: 3
                },
                480: {
                    slidesPerView: 2
                }
            }
        });

        modelSlider.on('transitionEnd', function() {
            // App.makeModelTilesActive();
            modelSlider.update();
            // App.translateSlider(modelSlider);
        })

        // App.makeModelTilesActive();

        $(window).resize(function() {
            // modelSlider.update();

        })

    }
}

App.translateSlider = modelSlider => {

    if (window.innerWidth > 1306) {
        var actualTranslate = modelSlider.getTranslate();
        modelSlider.setTranslate(actualTranslate-107);

    }
}