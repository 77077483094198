var App = window['App'] = window['App'] || {};
// funkcje walidacji

App.validateMethods = () => {

    $.validator.addMethod("emailCheck", function( value, element ) {
        return this.optional( element ) || /^.+@.+\.[A-Za-z]{2,}$/.test( value );
        }, window.language.correctEmail);
    
    $.validator.addMethod("lettersonly", function(value, element) {
        return this.optional(element) || /^[a-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s]+$/i.test(value);
        }, window.language.onlyLetters);
    
    $.validator.addMethod("digitsonly", function(value, element) {
        return this.optional(element) || /^[0-9]+$/i.test(value);
        }, window.language.onlyDigits);

    $.validator.addMethod("phonePL", function(phonenr, element) {
        phonenr = phonenr.replace(/\s+/g, "");
        return this.optional(element) || phonenr.length > 8 && phonenr.length < 10 &&
            phonenr.match(/^(0-?)?(\([0-9]\d{2}\)|[0-9]\d{2})-?[0-9]\d{2}-?\d{3,4}$/);

        }, window.language.correctPhone);

}